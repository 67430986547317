.product-banner .site__banner {
  background-image: url(../../../assets/images/productbg.png);
  background-repeat: no-repeat;
  min-height: 600px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: initial;
  text-align: end;
  background-position-x: center;
}

// .product-banner .site__banner::after{
//     display: none;
// }
.product-banner .site__banner::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 960px;
  height: 100%;
  background: transparent linear-gradient(90deg, #000000 0%, #d8dddd00 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
}

.product-site-heading {
  font-size: 180px;
  color: white;
}

.product-shifa-content h6 {
  color: white;
}

.product-shifa-content {
  max-width: 539px;
  margin-right: auto;
  text-align: right;
  line-height: 160px;
}

.products-tabs-wrapper .alim__nabeela_content {
  background-image: url(../../../assets/images/Union.png);
  background-repeat: repeat;
  height: 100%;
  background-color: #d8dddd19;
}

.product-faqat-wraper .main-wraper-faqat.mt-20 {
  margin-top: 0;
}

.product__slider-wrap .coleague-wrap::after {
  content: "";
  background: transparent
    linear-gradient(
      180deg,
      #ffffff00 0%,
      #55555500 16%,
      #00000019 57%,
      #000000c6 100%
    )
    0% 0% no-repeat padding-box;
  border-radius: 10px;
  height: 100%;
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
}

.product__slider-wrap .slick-slide {
  transform: none;
  transition: transform 0.5s;
  opacity: 1;
}

.product__slider-wrap .shahdat-wrapper .slick-slide::after {
  display: none;
}

.product__slider-wrap .coleague-wrap {
  margin: 0 auto;
}

.product__slider-wrap .slick-prev {
  left: -16px;
  top: 31%;
}

.product__slider-wrap .slick-next {
  top: 31%;
  right: 10px;
}

.product__slider-wrap .slick-prev:before {
  content: "";
  color: red;
  background: url(../../../assets/images/left-arrow.png);
  height: 53px;
  width: 53px;
  display: block;
}

.product__slider-wrap .slick-next:before {
  content: "";
  color: red;
  background: url(../../../assets//images/right-arrow.png);
  height: 53px;
  width: 53px;
  display: block;
}

@media (max-width: 1680px) {
  .coleague-wrap {
    max-width: 416px;
  }
}

@media (max-width: 1480px) {
  .product-banner .site__banner {
    min-height: 385px;
    background-size: 100%;
    background-size: cover;
    background-position-x: center;
  }
}

@media (max-width: 1280px) {
  .product__slider-wrap .coleague-wrap {
    max-width: 323px;
  }

  .product-wraper {
    max-width: 323px;
  }

  .product__slider-wrap .slick-next {
    top: 31%;
    right: 20px;
  }

  .product__slider-wrap .slick-prev {
    left: -8px;
    top: 31%;
  }
}

@media (max-width: 1180px) {
  .product__slider-wrap .coleague-wrap {
    max-width: 100%;
  }

  .product-wraper {
    max-width: 96%;
  }
}

// @media (max-width:991px){

//     .product__slider-wrap .coleague-wrap {
//         max-width: 386px;
//     }
//     .product-wraper {
//         max-width: 386px;
//     }
// }

@media (max-width: 767px) {
  .product-banner .site__banner {
    min-height: 436px;
  }

  .product-site-heading {
    font-size: 105px;
  }

  .product__slider-wrap .coleague-wrap {
    max-width: 100%;
  }

  .product-wraper {
    max-width: 100%;
  }
}

@media (max-width: 575px) {
  .product-banner .product-shifa-content {
    max-width: 100%;
    text-align: center;
    line-height: 67px;
  }

  .product-banner .product-site-heading {
    font-size: 80px;
  }

  .product-banner .site__banner {
    min-height: 289px;
  }

  .product-shifa-content h6 {
    font-size: 36px;
}

  .alim__nabeela_content .fee-alim-wrapper {
    padding-top: 30px;
  }

  .product_shahat-text h2 {
    font-size: 40px;
    font-weight: 300;
  }
}

@media (max-width: 480px) {
  .product-shifa-content h6 {
    font-size: 34px;
  }

  .products-tabs-wrapper .alim__nabeela_content {
    padding-top: 30px;
    padding-bottom: 40px;
  }

  .products-tabs-wrapper .fee-alim-wrapper h2 {
    font-size: 29px;
  }

  .product-banner .site__banner {
    min-height: 228px;
  }

  .alim__nabeela_content .fee-alim-wrapper {
    padding-top: 0px;
  }

  .tabs-ali-wraper .mt-20 {
    margin-top: 30px;
  }
}
