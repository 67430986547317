.sidebar {
    width: 314px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    transition: transform 0.3s ease;
    position: fixed;
    right: 0; 
}
  button.toggle-button {
    display: none !important;
  }
  .logo-wraper {
    width: 313px;
    height: 93px;
    background: #F5F5F5;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #E7E7E7;
}
.dashboard-pages {
  height: calc(100vh - 93px);
}
.dashboard-pages .menu {
  background-color: #FF5D00;
  padding: 50px 30px 0 0;
  height: calc(100vh - 185px);
  overflow-y: auto;
}
  .menu {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .menu-item {
    padding: 25px 15px;
    margin-bottom: 10px;
    font-size: 27px;
    line-height: 25px;
    color: #F5F5F5;
    font-weight: 500;
    border-radius: 4px;
    cursor: pointer;
    text-align: right;
    letter-spacing: 0px;
    display: flex;
    align-items: center;
  }
  
  .menu-item:hover {
    background-color: #fff;
    color: #ff5d00;
    border-radius: 0px 40px 40px 0px;
}
  .singout .menu-item:hover {
    background-color: transparent;
    color: #fff;
    border-radius: unset;
}
  .menu-item span >svg {
    width: 29px;
    height: 29px;
    // object-fit: contain;
}
.sidebar .menu-item .active {
    background-color: #fff;
    color: #D5AD67;
    border-radius: 0px 40px 40px 0px;
  }
  
  .sidebar .menu-item .active:hover {
    background-color: #E7E7E7;
    border-radius: 0px 40px 40px 0px;
  }
  
  .toggle-button {
    margin-bottom: 10px;
    cursor: pointer;
  }
  .dashboard-pages ul li a.active svg path{
    fill: #ff5d00 !important;
  }
  .menu-item:hover  svg path{
    fill: #ff5d00 !important;
  }
  span.white-circle {
    width: 37px;
    height: 37px;
    border-radius: 50%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}
.singout {
  border-top: 1px solid #E3DDD5;
  background: #FF5D00;
  padding: 3px 30px 0 0;
}
.event-details {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}
.dashboard-pages a.menu-item.active {
  background: white;
  color: #D5AD67;
  border-radius: 0px 40px 40px 0px;
}
.w-50{
  width: 200px;
}
@media only screen and (max-width: 1399px) {
    .logo-wraper {
      width: 280px;
      height: 76px;
  }
  .sidebar {
    width: 280px;
  }
  .menu-item {
    padding: 13px 12px;
    margin-bottom: 5px;
    font-size: 15px;
    line-height: 20px;
  }
  .menu-item span > svg {
    width: 20px;
    height: 22px;
  }
  .dashboard-pages .menu {
    height: calc(100vh - 143px);
  }
  ul.event--list li {
    padding: 10px 25px 6px 6px;
    margin-top: 10px;
  }

}
@media only screen and (max-width: 1199px) {
  button.toggle-button{
    display: block !important;
  }
.sidebar.open {
  /* styles for when the sidebar is open */
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  z-index: 2;
  }

.sidebar.close{
  /* styles for when the sidebar is closed */
  transform: translateX(300px);
  -webkit-transform: translateX(300px);
  -moz-transform: translateX(300px);
  -ms-transform: translateX(300px);
  -o-transform: translateX(300px);
}
button.toggle-button {
  width: 48px;
  height: 45px;
  font-size: 30px;
  color: #D5AD67;
  background: #fff;
  border-radius: 4px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 300px;
  top: 12px;
  margin: 0 auto;
}
}
@media only screen and (max-width: 575px) {
  .sidebar.open .toggle-button {
    right: 270px;
  }
}