@media (max-width: 1780px) {
.theme__container {
    max-width: 1564px;
}
}

@media (max-width: 1680px) {
.theme__container {
  max-width: 1465px;
}
.f-size-180 {
  font-size: 164px;
}

.shifa-content h6 {
  font-size: 180px;
  max-width: 627px;
  line-height: 80px;
}
.site__banner {
  min-height: 577px;
}
.site__banner::after {
  width: 786px;
}
.content-text{
  max-width: 824px;
}
.fee-alim-wrapper h2 {
  font-size: 55px;
}
.shahat-text h2 {
  font-size: 100px;
  line-height: 84px;
}
.aljadid__video .coleague-wrap {
  max-width: 397px;
}
.listing_alim ul li {
  padding: 15px 25px;
  max-width: 584px;
}
.product-banner .site__banner {
  min-height: 500px;
}
}
@media (max-width: 1540px) {
.theme__container {
  max-width: 1309px;
}
.aljadid_conent{
  margin-bottom: 10px;
}
.jadid-videolame video {
  height: 426px;
}
.aljadid__video .coleague-wrap {
  max-width: 349px;
}
.listing_f-sze {
  font-size: 18px;
}
.listing_alim ul li {
  max-width: 488px;
}
.field-wrapper.buton-holder .theme-btn {
  max-width: 248px;
  height: 56px;
  margin-right: -92px;
  background: #000;
}
}

@media (max-width: 1480px) {
.theme__container {
        max-width: 1280px;
}
.f-md-size{
  font-size: 14px;
}

.shifa-content h6 {
      font-size: 160px;
}
.hayat-text {
    font-size: 22px;
}
.site__banner {
  min-height: 572px;
}
.content-text {
  max-width: 630px;
}
.f-size-26{
  font-size: 20px;
}
.shahdat-wrapper .shahadat-slider {
  min-height: 336px;
}
.faqat-text {
  min-height: 347px;
}
.faqat-wraper {
  min-height: 347px;
  object-fit: contain;
  background-position-x: center;
}
.listing_alim ul li {
  max-width: 502px;
}
}

@media (max-width: 1367px) {
  .social__icons_main img{
    width: 20px;
}
.social__icons_main {
  width: 40px;
  height: 40px;
  left: -18px;
}
  .theme__container {
  max-width: 1200px;
  }
  .cms-pages-baner.chechout_banner.page_not_found {
  padding: 94px 0px;
}
  .aljadid__video .coleague-wrap {
  max-width: 331px;
}
  .product-wraper {
  max-width: 371px;
}
.cart-lottie{
	margin-top: 0;
	width: 60px;
  height: 60px;
}
.theme-btn {
	font-size: 16px;
}
.f-size-17 {
  font-size: 15px;
}
.f-md-size{
  font-size: 14px;
}
  .site__logo img{
  width: 300px;
  }
  .shifa-content h6 {
    font-size: 170px;
    max-width: 478px;
    line-height: 80px;
}
.site__banner {
  min-height: 475px;
  background-position-x: center;
  background-size: 100%;
}
  .mansat-videolame video {
    height: 393px;
  }
  .site__banner::after {
    width: 589px;
  }
  .content-text {
    max-width: 516.1px;
  }
  .coleague-wrap {
    height: 280px;
}
.fee-alim-wrapper h2 {
  font-size: 45px;
}
.jadid-videolame video {
  height: 350px;
}
.site__header__innertop {
  padding: 24px 0px;
}
.main-navbar{
  padding: 20px 0px;
}
.site__header__inner {
  padding: 0px 0px;
}
.font-size-18 {
  font-size: 14px;
}
.colleage-text p {
  font-size: 20px;
  color: white;
}
.listing_f-sze{
  font-size: 14px;
}
.mobile-text .mola {
  font-size: 14px;
  padding: 10px 0px;
}
.shahadat-slider h3 {
  font-size: 25px;
}
.nonimg {
  top: -3px;
  width: 400px;
}
.alim__nabeela_content {
  background-position-x: center;
}
.shahat-text h2 {
  font-size: 80px;
  line-height: 84px;
}
.file_format_header_wrapper .video_orginal_name {
  min-width: 463px;

  max-width: 238px
}
}
@media (max-width: 1280px) {
  .theme__container {
    max-width: 1100px;
  }
  .file_format_header_wrapper .video_orginal_name {
    min-width: 234px;
  }
  .file_format_header_wrapper .video_file_format {
    min-width: 197px;
  }
  .file_format_header_wrapper .audio_corse.custom_audio_course audio {
    max-width: 188px;
  }
  .listing_alim ul li {
    max-width: 399px;
}
}

@media (max-width: 1180px) {
  .theme__container {
    max-width: 1000px;
  }
  .site__banner::after {
  
    width: 600px;
}
.site__banner {
  min-height: 482px;
}
.site__logo img {
  width: 248px;
}
.mansat-videolame video {
  width: 389px;
  height: 353px;
}
.content-text {
  max-width: 456.1px;
}

.site__header__inner {
  padding: 19px 0px;
}
.aljadid__video .coleague-wrap {
  max-width: 294px;
}
label.subscribe-lable {
  font-size: 24px;
}
.theme-btn {
  font-size: 20px;
}
}

@media (max-width: 991px) {
  .theme__container {
    max-width: 900px;
  }
  .benefits_card {
    min-height: 203px !important;
    height: unset !important;
}
.field-wrapper.buton-holder .theme-btn {
  max-width: 190px;
  height: 45px;
  margin-right: -92px;
}
label.subscribe-lable {

  font-size: 17px;
}
.f-size-27 {
  font-size: 19px;
}
.f-size-180 {
  font-size: 130px;
  line-height: 111px;
}

label.subscribe-lable {
  padding: 10px;
}
.field-wrapper {
  width: 41%;
  margin-top: 25px;
}
.footer::after {
  height: 237px;
  top: 15px;
  right: -63px;
  background-size: 412px;
  background-repeat: no-repeat;
}
.file_format_header_wrapper .video_orginal_name {
  min-width: 106px;
}
  .site__header__inner {
    width: 0;
}
.site__header__innertop {
  padding: 23px 0px;
}
.mobile-bar{
  margin: 10px 0px;
  padding: 0 15px;
}
.site__banner {
  background-size: cover;
}
  .font-size-18{
    font-size: 14px;
  }
  .nonimg img{
    width: 282px;
  }
  .nonimg {
    top: 48px;
    right: 0;
  }

  .rectangle_img svg {
    width: 144px;
  }  
  .lastanimated {
    top: 23px;
    right: -19px;
  }
  .mobile_wraper img {
  
    width: 100%;
  }
  .Profile_wraper img{
    width: 100%;

  }
  .mobile-text .tmail-btn{
    margin-bottom: 30px;
  }
  .shahat-text h2 {
    font-size: 69px;
    line-height: 84px;
  }
    .faqat-wraper {
      background-position-x: center;
      background-size: 100% 100%;
      min-height: 266px;
  }
  .faqat-wraper {
    min-height: 254px;
  }
  .jadid-videolame video {
    height: 385px;
  }
  .site__banner {
    text-align: center;
  }
  .site__banner .theme-btn{
    margin: 20px auto;
  }
  .alwai{
    padding-top: 4px;
  }
  .hayyat-wrapper{
    flex-direction: column;
  }
  .content-text {
    max-width: 100%;
  }
  .mansat-videolame{
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .mansat-videolame video {
    width: 100%;
  }
  .site__banner::after {
    width: 100%;
  }
  .tabs-ali-wraper{
    margin-top: 5px;
  }
  .kaif-heading{
    padding-top: 20px;
  }
  .circle-block {
    width: 28px;
    height: 28px;
  }
  .hamburger-lines {
    display: flex;
}
.site__header__inner.active nav {

  background: #fff;
  border: 1px solid #ececec;
  border-radius: 8px;
  display: block;
  left: 15px;
  position: absolute;
  top: 178px;
  width: calc(100% - 52px);
  z-index: 99;

}
.coleague-fifth {
  background-position-x: center;
}
.coleagesecond {
    background-position-x: center;
}
.coleagefirst{
  background-position-x: center;

}
.site__header__inner nav {
  align-self: center;
}
.site__header__inner nav ul {
  flex-direction: column;
}
.site__header__inner nav {
  display: none;
}
.main-navbar li {
  padding-right: 35px;
}
.site__header__inner nav ul li a {
  border-bottom: 1px solid #f7f7f7;
  display: block;
  font-size: 30px;
  padding: 12px 15px;
}
.site__header.relative .desktop-block{
  display: none;
}
.mobile-block{
  display: block;
}
.site__logo img {
  width: 297px;
}
.f-size-26 {
  font-size: 18px;
}
.faqat-text .text-white.text-4xl {
  font-size: 40px;
}
.aljadid__video .slick-next {
  right: 167px;
}
.aljadid__video .slick-prev {
  left: 191px;
}
.mobile-text-content{
  display: flex;
  flex-direction: column-reverse;
}
.listing_alim ul li:nth-child(even){
  margin-right: 0px;
}
.listing_alim ul li {
  max-width: 600px;
}
}

@media (max-width: 767px) {
  .theme__container {
    max-width: 694px;
  }
  .cms-pages-baner.chechout_banner.page_not_found {
    padding: 47px 0px;
}
.field-wrapper.buton-holder .theme-btn {
  margin-right: -80px;
}
label.subscribe-lable {
  font-size: 16px;
}
.left-reviews-section_course.main_left_part svg {
  left: 13px;
  top: 36px;
  width: 22px;
  height: 34px;
}
.user-cion {
  margin: 0 10px;
  align-items: center;
}
 .theme-btn {
  font-size: 12px;
  padding: 10px 16px;
}
.listing_alim ul li {
  padding: 15px 11px;
}
.KaifContent {
  padding-bottom: 37px;
}
 .site__banner {
  background-position-x: center;
  background-size: cover;
  min-height: auto;

}
.site__banner .relative.z-10{
  padding: 40px 0px;
}
 .site__logo img {
  width: 297px;
}
.kaiftext-sm {
  font-size: 20px;
}
.mola{
  padding-top: 10px;
}
.mobile_wraper{
  margin-top: 30px;
}

.jadid-videolame video {
  height: 313px;
}
.content-text h2{
  font-size: 22px;
}
.hayyat-wrapper{
  padding-top: 30px;

}
.site__header__inner.active nav {
 
  top: 148px;

}
.aljadid__video .slick-prev {
  left: 118px;
}
.aljadid__video .slick-next {
  right: 74px;
}
.footer::after {
display: none;
}
.albi-listing ul {
  flex-direction: column;
}
.albi-listing ul li{
  padding-left: 0;
  padding-bottom: 11px;
  border-bottom: 1px solid white;
  width: 137px;
  text-align: center;
  padding-top: 8px;
 }
 .video_format_audio {
  min-width: 119px;
 }
 .font-size-27 {
  font-size: 20px;
  line-height: 19px;
}
.f-size-52 {
  font-size: 26px;
}
.no_course_fd {
  font-size: 25px;
  line-height: 26px;
}
.f-size-180 {
  font-size: 80px;
  line-height: 56px;
}
}
@media (max-width: 575px) {
  .shahat-text h2 {
    font-size: 54px;
    line-height: 84px;
  }
  .f-size-27 {
    font-size: 22px;
}
.theme-btn {
  font-size: 22px !important;
}
.no_course_fd {
  line-height: 20px;
}
  .field-wrapper {
    width: 51%;
    margin-top: 25px;
}
  .aljadid__video .slick-prev:before {
    background-size: 28px;
    background-repeat: no-repeat;
}
.file_format_header_wrapper .video_file_format {
  min-width: 56px;
}
.aljadid__video .coleague-wrap {
  max-width: 100%;
  height: 220px;
}

.aljadid__video .slick-next:before {
  background-size: 28px;
  background-repeat: no-repeat;
}
.footer::after {
  top: 169px;
}
.field-wrapper.buton-holder .theme-btn {
  max-width: 179px;
  height: 43px;
  margin-right: -108px;
  font-size: 12px;

}


label.subscribe-lable {
  padding: 8px 10px;
  font-size: 16px;
}
.faqat-text {
  min-height: 255px;
}
.faqat-text .text-white.text-4xl {
  font-size: 26px;
}
  .jadid-videolame video {
    padding-left: 0px;
  }
  .aljadid__video .slick-prev {
    left: 25px;
  }
  .aljadid__video .slick-next {
    right: 55px;
  }
  .main-wraper-faqat {
    margin-top: 0px;
  }
  .shahat-text .pb-12{
    padding-bottom: 10px;
  }
 .Aljadid__content{
  margin-bottom: 20px;
 }
 .social__icons_main {
  width: 50px;
  height: 50px;
  top: -30px;
  left: -13px;
}
 .nonimg img {
  display: none;
}
.albi-listing{
  padding-top: 20px;
  margin-top: 20px;
}
.shahdat-wrapper{
  margin-top: 20px;
}

.Aljadid__content{
  margin-top: 30px;
}
.Profile_wraper{
  margin-top: 30px;
}
.Profile_wraper::after {
 
  bottom: -27px;
  right: 29px;
}
.rectangle_img{
  margin-left: 30px;
}
.rectangle_img svg {
  width: 110px;
  height: 132px;
}
.lastanimated {
  top: -31px;
  right: -41px;
}
.alim__wrapr {
  margin-top: 40px;
}
.top-scroll-img button {
  left: 42px;
  width: 61px;
}
.shahdat-wrapper .slick-prev{
  top: 67%;

}
.shahdat-wrapper .slick-next{
  top: 67%;
}
.alim__nabeela_content {
  min-height: 348px;
  background-size: cover;
}
.alim__wrapr::after {
  min-height: 340px;
}

.alim__wrapr::after {
  min-height: 340px;
}
.courses_detail_video video {
  height: 267px;
}
.courses_join_btn {
  margin-top: 45px;
  z-index: 1;
}
.event-pleasent {
  width: 100%;
}
.cms-pages-baner.contact-baner {
  padding: 49px 0;
}
.shifa-content p {
  font-size: 44px;
}
.font-size-27 {
  font-size: 23px;
  line-height: 17px;
}
}

@media (max-width: 480px) {
  .mbl-f-size {
    font-size: 20px;
}

.hadas_text{
  text-align: center !important;
}
  .mobile-text.mt-2.mr-6{
    padding-top: 30px;
  }
  .file_format_header_wrapper .video_orginal_name {
    min-width: 14px;
}
  .Aljadid__content {
    margin-bottom: 40px;
}
.field-wrapper {
  width: 71%;
  margin-top: 25px;
}
  .hayyat-wrapper{
    padding-top: 30px;
  }
  .site__logo img {
    width: 214px;
  }
  .shahdat-wrapper .shahadat-slider {
    min-height: 252px;
  }
  .main-wraper-faqat {
    margin-top: -19px;
  }
  .KaifContent {
    margin-top: 30px;
  } 
 
  .site__banner {
    min-height: 313px;
  }  
  .animation svg{
    height: 100px !important;
  }
  .lotteplayer{
    height: 100px !important;
    width: 100px !important;
  }
  .lastanimated {
    top: 0px;
    right: -10px;
  }
  .rectangle_img svg {
    width: 75px;
    height: 105px;
  }  
  .alwai {
    padding-top: 4px;
    font-size: 20px;
  }
  .fee-alim-wrapper h2 {
    font-size: 34px;
  }
  .mobile-text ul{
    padding-top: 20px;
  }
 
  .select-flag {
    width: 150px;
    max-width: 94px;
    min-height: 32px;
  }
  .flag-wraper {
    height: 14px;
    width: 20px;
  }
  .f-size-16{
    font-size: 14px;
  }
  .user-cion span {
    font-size: 25px;
}
  .social__icons ul li {
    padding-left: 11px;
  } 
  .search_input input {
    bottom: -42px;
  }
  .hamburger-lines {
    height: 26px;
    width: 32px;
  }  
  .flex.items-center.my-6{
    margin: 15px 0px;
  }
  .theme-btn {
    font-size: 13px;
  }
  .site__banner {
    min-height: 221px;
}

.shifa-content h6 {
  font-size: 87px;
  max-width: 314px;
  line-height: 53px;
  text-align: center;
}
  .content-text h2{
    margin-bottom: 10px;
    font-size: 19px;
  }
  .reaction-action img{
    height: 60px;
  }
  .reaction-action {
    margin-top: -31px;
  }
  .kaiftext-sm {
    font-size: 21px;
  }
  .KaifContent {
    margin-top: 30px;
    padding-bottom: 30px;
  }
  .aljadid_conent h2{
    font-size: 24px;
  }
  .f-size-26 {
    font-size: 27px;
}

  .top-scroll-img button {
    left: 11px;
    width: 51px;
}


  .playicon_player {
    height: 100px !important;
    width: 100px !important;
  }
  .mobile-text.mt-2.mr-6{
    margin-right: 0px;
  }
 

  .jadid-videolame video {
    height: 249px;
  }
  .shahadat-slider h3{
    padding-bottom: 30px;
  }
  .aljadid_conent p{
    padding-top: 5px;
  }
  .hayat-text {
    font-size: 16px;
  }  
  .site__header__inner.active nav {
    width: calc(100% - 34px);
  }
 .mobile_wraper {
  margin-top: 5px;
}
.site__banner .relative.z-10 {
  padding: 22px 0px;
}
.courses_join_btn {
  margin-top: 94px;
  z-index: 13;
}
.no_course_fd {
  font-size: 26px;
}
.product-banner .product-site-heading {
  font-size: 52px;
}
.product-banner .site__banner {
  min-height: 149px;
}
.file_format_header_wrapper .play_cus_btn {
  min-width: 70px;
  margin: 14px auto;
}
.file_format_header_wrapper .cus_download_btn {
  max-width: 73px;
  margin: 0 auto;
}
.file_format_header_wrapper .audio_corse.custom_audio_course audio {
  max-width: 106px;
}
.shifa-content p {
  margin-top: 0px;
  font-size: 32px;
  text-align: center;
}
}