.cms-pages-baner.chechout_banner{
    background-image: url(../../../assets/images/checkbg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding:125px 0;
    position: relative;
  }
  .chechout_banner .cms-content-heading {
    max-width: 545px;
    margin:0 auto ;
    text-align: right;
    z-index: 2;
    position: relative;
  }
  .text-100{
    font-size: 100px;
  }
  .chechout_banner .cms-content-heading {
    max-width: 745px;
  }
  .form--wraper{
    position: relative;
  }
  .chechout_banner_wrapper .form--wraper::before{
    content: "";
    position: absolute;
    background-image: url(../../../assets/images/carryout.png);
    background-size: 100%;
    background-repeat: no-repeat;
    right: 0;
    top: 0;
    width: 375px;
    height: 332px;
    z-index: 1;
  }

  .form--wraper::after {
    content: "";
    position: absolute;
    left: 42%;
    top: 50%;
    width: 191px;
    height: 100%;
    z-index: 1;
    background: #D8DDDD;
    transform: translate(-50%, -50%);
  }
  .cart--pg .form--wraper::after{
    display:none;
  }
  .cart--pg .common-card-style{
    display: flex;
  }
  .cart--pg .contact--info{
    margin: 0 auto;
    width: 70% !important;
  }
  .chechout_banner_wrapper .contactus--form .common-card-style{
    padding: 70px;
  }
  
 .chechout_banner form#contact-form {
    padding-top: 114px;
  }
  textarea.form-control {
    height: 133px;
    resize: none;
  }
    .form-control {
      display: block;
      width: 100%;
      height: 60px;
      padding: 6px 12px;
      font-size: 16px;
      line-height: 26px;
      font-weight: 300;
      border-radius: 4px;
      border: 1px solid #D8DDDD;
      border-radius: 10px;
      color: #373737;
      background-color: #fff;
  }
  label.lable-style {
    letter-spacing: 0px;
    color: #373737;
    font-size: 17px;
    line-height: 26px;
    font-weight: 300;
    color: #373737;
    margin-bottom: 5px;
  }
  .contact-right-form {
    padding: 0 0 50px 50px;
    z-index: 2;
    position: relative;
  }
  .chechout_banner_wrapper .contact--info {
    background: #F5F5F5;
    padding: 0px;
    position: relative;
    z-index: 2;
    margin-right: auto;
    width: 83%;
  }
  ul.contact-sources {
    padding-top: 60px;
  }
  .contact-sources li{
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  .source-name{
    margin-left: 16px;
  }
  .source-lato{
    font-family: 'Lato';
    font-size: 17px;
    font-weight: normal;
  }
  span.img-container {
    width: 48px;
    height: 48px;
    background: #D8DDDD;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    margin-left: 19px;
  }
  .map-setting {
    height: 180px;
    width: 100%;
  }
  iframe.map {
    box-shadow: 0px 0px 16px #0000000F;
    border: 1px solid #FFFFFF;
    border-radius: 10px;
    height: 100%;
  }
  .social-icons {
      padding-top: 50px;
  }
  ul.social-links {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 47px;
  }
  ul.social-links li{
    padding-left: 22px;
    cursor: pointer;
  }
  ul.social-links li a img{
    width: 32px;
    height: 22px;
  }
  .checkout-heading{
    padding: 49px 0px;
    background:  #FF5D00 0% 0% no-repeat padding-box;
    position: relative;
  }
  .checkout-heading::after{
    content: "";
    background-image: url(../../../assets/images/hurdles.svg);
    background-repeat: repeat;
    position: absolute;
    width: 100%;
    bottom: -1px;
    right: 0;
    height: 9px;
  }
  .padding-50{
    padding: 50px;
  }
  .dark_shadow{
    text-shadow: 0px 3px 6px #00000000;
  }
  .price_listing{
    padding: 12px 50px ;
  }
  .banking-card{
    padding: 0 50px;
  }
  .banking_content p{
    margin-top: 29px;
    padding: 20px 12px;
    background-color: white;
  }
  .visa_card{
    padding: 15px 10px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    opacity: 1;
    max-width: 276px;
    width: 100%;
    margin-left: 10px;
  }
  .benfit_card{
    padding: 5px 10px;
    background-color:white;
    max-width: 176px;
    width: 100%;
    text-align: -webkit-left;
  }
  .select_identity select{
    display: block;
    width: 100%;
    height: 60px;
    padding: 6px 12px;
    font-size: 16px;
    line-height: 26px;
    font-weight: 300;
    border: 1px solid #D8DDDD;
    border-radius: 10px;
    color: #373737;
    background-color: #fff;
    outline: none;
    appearance: none;
    background: url(../../../assets/images/downarrow.svg);
    background-repeat: no-repeat;
    background-position-x: calc(16px);
    background-position-y: calc(100% - 19px);
    opacity: 0.5;
  }
  .visa_card img{
    width: 80px;
    height: 72px;
  }
 
  // .lettalkwrapper{
  //   max-width: 600px;
  // }
  // .expertise{
  //   -webkit-text-strokeWidth: 2px;
  //   -webkit-text-fill-color: transparent;
  // }
  // .contact-wrapper {
   
  //   margin: 0 auto;
  //   padding: 20px;
  //   position: relative;
  //   max-width: 640px;
  //   flex: 0 0 640px;
  // }
  
  // .form-control, 
  // textarea {
  //   background-color: transparent;
  //   color: #fff;
  //   letter-spacing: 1px;
  // }
  @media only screen and (max-width: 1700px) {
    .visa_card img {
      width: 80px;
      height: 50px;
  }
  }

  @media only screen and (max-width: 1480px) {
    .form-control {
      height: 50px;
      font-size: 14px;
    }
    .select_identity select{
      height: 50px;
      font-size: 14px;
    }
   
    ul.contact-sources {
      padding-top: 37px;
    }
    .chechout_banner_wrapper .contactus--form .common-card-style {
      padding: 31px;
  }
  .chechout_banner_wrapper .form--wraper::before {
    width: 244px;
    height: 275px;
  }
  .banking-card {
    padding: 0 29px;
  }  
  .visa_card img{
    width: 73px;
    height: 50px;
  }
  .benfit_card {
    padding: 1px 8px;
    max-width: 152px;
    margin-left: 20px;
  }
    }
    @media only screen and (max-width: 1280px){
      .visa_card {
        max-width: 258px;
        width: 100%;
        font-size: 12px;
      }
    }
    @media only screen and (max-width: 992px){
      .chechout_banner_wrapper .contact--info {
          background: #F5F5F5;
          padding: 0px;
          position: relative;
          z-index: 2;
          margin-right: auto;
          width: 100%;
      }
      .check_out-btn{
        margin-bottom: 20px;
      }
    .cms-pages-baner.chechout_banner {
        background-repeat: no-repeat;
        padding: 80px 0px;
        position: relative;
    }
  }


@media only screen and (max-width: 767px){
    .contact-right-form {
      padding: 0 0 30px 0px;
    }
    .cms-pages-baner.chechout_banner {
      background-size: cover;
    }
    .form-control {
      height: 40px;
      font-size: 14px;
  }
  .select_identity select{
    height: 40px;
  }
  label.lable-style {
    font-size: 14px;
  }
}

@media (max-width: 575px){
  .bankind__cards{
    flex-direction: column;
  }
  .visa_card{
    margin-bottom:10px;
  }
  .benfit_card {
    max-width: 100%;
    margin-bottom: 15px;
    margin-left: 0;
  }
  .visa_card {
    max-width: 100%;
    width: 100%;
  }
  .banking-card {
    padding: 14px 29px;
  }
  .padding-50 {
    padding: 30px;
  }
  .price_listing {
    padding: 12px 30px;
  }
  .cms-pages-baner.chechout_banner {
    padding: 35px 0px;
  }

}