@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";


@font-face {
    font-family: 'AGA Rasheeq V.2';
    src: url('../fonts/AGARasheeqV2-Bold.woff2') format('woff2'),
        url('../fonts/AGARasheeqV2-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'AGA Rasheeq V.2';
    src: url('../fonts/AGARasheeqV2.woff2') format('woff2'),
        url('../fonts/AGARasheeqV2.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
// added new font family for add to cart itmes price
@font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato-Bold.woff') format('woff2'),
        url('../fonts/Lato-Bold.woff2') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato-Regular.woff2') format('woff2'),
        url('../fonts/Lato-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


$yellow-clr: #D59423;
$light-yellow: #D5AD67;
.light-yellow{
color: #D5AD67;

}
.f-size-56{
	font-size: 56px;
}

.f-size-26{
	font-size: 26px;
}
.dark_color{
	color: #373737;
}
html,body{
	scroll-behavior: smooth;
	direction: rtl;
}
body {
	min-height: 100vh;
	font-weight: 400;
    font-family: 'AGA Rasheeq V.2';
	color: #373737;
}
.yellow-clr{
	color: $yellow-clr;
}
.f-size-17{
	font-size: 17px;
}
.social__icons ul li{
	padding-left: 17px;
}
.theme__container{
	max-width: 1564px;
	margin: 0 auto;
	padding: 0 15px;
}
// react-select dropdown style
.common-select-style__control {
    width: 100%;
    height: 60px;
    padding: 6px 12px;
    font-size: 26px;
    line-height: 26px;
    font-weight: 300;
    border: 1px solid #D8DDDD;
    border-radius: 10px !important;
    color: #373737;
    background-color: #fff;
    -webkit-border-radius: 10px !important;
    -moz-border-radius: 10px !important;
    -ms-border-radius: 10px !important;
    -o-border-radius: 10px !important;
}

.common-select-style__control,
.common-select-style__option{cursor:pointer !important;}
.common-select-style__placeholder{color: #D8DDDD!important;;}
.common-select-style__control--is-focused{box-shadow:0 0 0 1px #D59423 !important;border-color:#D59423 !important;}
.common-select-style__option--is-focused{background:#D8DDDD !important;color:#000 !important;}
.common-select-style__option--is-selected{background:#D5AD67 !important;color:#fff !important;}
.common-select-style__menu-list{padding:0 !important;overflow: hidden;}
.css-1u9des2-indicatorSeparator{
	border: none !important;
	display:none ;
}
// form-control style
textarea.form-control {
	height: 133px;
	resize: none;
  }
	.form-control {
	  display: block;
	  width: 100%;
	  height: 60px;
	  padding: 6px 12px;
	  font-size: 16px;
	  line-height: 26px;
	  font-weight: 300;
	  border-radius: 4px;
	  border: 1px solid #D8DDDD;
	  border-radius: 10px;
	  color: #373737;
	  background-color: #fff;
  }
  input:focus-visible,textarea:focus-visible{
	outline: 2px solid #FF5D00;
	box-shadow:0 0 0 1px #FF5D00; 
  }
  .common-select-style__input-container input[type=text]:focus-visible{
	outline: none;
	box-shadow:0 0 0 0;
  }
  label.lable-style {
	letter-spacing: 0px;
	color: #373737;
	font-size: 27px;
	line-height: 26px;
	font-weight: 300;
	color: #373737;
	margin-bottom: 5px;
  }
  .clock_box img{
    filter: invert(36%) sepia(85%) saturate(2204%) hue-rotate(3deg) brightness(105%) contrast(103%);
    width: 35px;
  }
// ribon style
.ribbon {
    padding: 18px 32px 17px 46px;
    background: #FF5D00;
	clip-path: polygon(95% 0, 95% 50%, 95% 100%, 0% 100%, 5% 50%, 0% 0%);
    color: #dddeda;
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
    text-shadow: 0px 3px 6px #00000000;
    height: 42px;
    font-family: 'Lato';
    font-size: 17px;
}
// ribon for product slider
.left-ribon.ribbon {
    clip-path: polygon(101% 0, 95% 50%, 101% 100%, 0% 100%, 0% 32%, 0% 0%);
	margin-left: -15px;
}

// styles for product hover

/* product overlay style */

.product-wraper {
    position: relative;
    display: inline-block;
    max-width: 496px;
    vertical-align: top;
  }
  
  .product-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 0;
    background-color: #000000BA;
    transition: height 0.3s ease-in-out;
    border-radius: 10px;
    visibility: hidden;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
}
  
.product-wraper:hover .product-overlay {
    height: 100%;
    visibility: visible;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
  .produtc-name-price {
    direction: rtl;
    z-index: 1;
    width: 100%;
    align-items: center;
  }

  .cart-lottie {
    width: 80px;
    height: 80px;
    margin: 0 auto;
    border-radius: 50%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
	margin-top: 65px;
}
// end
.main-navbar{
	border-top: 1px solid #EFEFEF;
}
.main-navbar li  {
	padding-left: 57px;
	font-weight: 500;
	font-size: 17px;
}
.site__header  .main-navbar li a::after {
	content: '';
    display: block;
    width: 0;
    height: 2px;
    background: #FF5D00;
    transition: width .3s;
}
.site__header__innertop{
	padding: 48px 0px;
}
.main-navbar li a:hover{
	color: #FF5D00;

}
.site__header__inner{
	width: 100%;
}

.main-navbar li a:hover::after {
    width: 100%;
}
.site__banner{
	background-image: url(../images/banner_image.png);
	background-repeat: no-repeat;
	min-height: 699px;
	display: flex;
    width: 100%;
    align-items: center;
    justify-content: initial;
    text-align: end;
	background-size: cover;
}
.theme-btn{
	background: #FF5D00 ;
    border-radius: 30px;
    color: #FFFFFF;
    font-size: 33px;
    cursor: pointer;
    padding: 7px 22px;
}

.site__header.relative .site-logo{
	position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.theme-btn:hover{
	background-color: #e3e3e3 !important;
	color: black !important;
}
.theme-btn:hover .circle-usrr svg path{
	fill: black;

}
.site-heading{
	line-height: 60px;
}
.hayat-text{
	font-size: 29px;
	font-weight: 300;
}
.shifa-content h6{
    font-size: 372px;
    font-weight: 700;
    max-width: 952px;
    line-height: 115px;
}
.shifa-content p{
    margin-top: 20px;
    font-size: 70px;
    text-align: end;
}
.shifa-content.text-white.leading-none{
    text-align: -webkit-left;;
}
.hayyat-text{
	max-width: 871px;
}
.font-size-18{
	font-size: 18px;
}
.site__banner{
	position: relative;
}
.font-serif{
    font-family: sans-serif;
}
.site__banner::after{
	content: "";
	position: absolute;
	left: 0;
	top:0;
    width: 803px;
	height: 100%;
	background: transparent linear-gradient(89deg, #000000A1 0%, #00000000 100%) 0% 0% no-repeat padding-box;
	opacity: 1;
}

.masnat-users img{
	height: 50px;
    width: 50px;
    border-radius: 50%;
    border: 1px solid white;
    object-fit: cover;
    margin-left: -12px;
}
.iqra-text h3{
	color: #373737;
}
.mansat-videolame video{
	box-shadow: 0px 3px 36px #00000057;
	border: 5px solid #FFFFFF;
	border-radius: 10px;
	width: 495px;
    height: 470px;
	object-fit: contain;
	object-position: center;
    z-index: 9;
    position: relative;
}
.select-flag{
	width: 200px;
    max-width: 119px;
	min-height: 39px;
	border: 1px solid #D8DDDD;
	border-radius: 6px;
	opacity: 1;
	display: flex;
    align-items: center;
    justify-content: center;
}
.flag-wraper{
	height: 20px;
	width: 30px;
	border: 0.5px solid #0000000D;
	border-radius: 2px;
}
.alim__nabeela_content{
	background-image: url(../images/CloudPray.png);
	background-repeat: no-repeat;
	min-height: 700px;
	position: relative;
}
.alim__wrapr{
	position: relative;
	
}
.animated-image{
	top: -86px;
    right: -26px;
}
.lastanimated{
	top: 23px;
	right: 28px;
}
.fee-alim-wrapper h2{
	font-size: 70px;
	text-shadow: 0px 3px 6px #00000000;
    line-height: 40px;
}
.tabs__wraper button{
	outline: none;
}
.coleague-wrap{
	background-repeat: no-repeat;
	height: 360px;
    max-width: 496px;
	width: 100%;
    background-position: center;
	position: relative;
	border-radius: 10px;
	display: flex;
    align-items: end;
	background-position-x: center !important;
    background-size: cover !important;
}

.coleagefirst{
	background: url(../images/coleageone.png);
}
.coleagesecond{
	background: url(../images/coleagetwo.png);	
}
.colleage-third{
	background: url(../images/coleagetwo.png);
}
.coleague-fifth{
	background: url(../images/colleagethird.png);
}
.coleague-wrap::after{
    content: "";
	background: transparent linear-gradient(180deg, #FFFFFF00 0%, #55555500 16%, #00000019 57%, #000000C6 100%) 0% 0% no-repeat padding-box;
    border-radius: 10px;
    height: 100%;
    position: absolute;
    width: 100%;
    top: 0;
    right: 0;

}
.jadid-videolame video{
	width: 100%;
    height: 552px;
	padding-left: 50px;
	margin: 0 auto;
}

.play-icon{
	top: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
	flex-direction: column;
}

.hamburger-lines {
	align-items: center;
	cursor: pointer;
	display: none;
	display: none;
	flex-direction: column;
	height: 28px;
	justify-content: space-between;
	width: 35px;
}
.hamburger-lines .line {
	background: #0d1953;
	border-radius: 10px;
	display: block;
	height: 3px;
	width: 100%;
}


.KaifContent{
	background-image: url(../images/Union.png);
	background-repeat: repeat;
	background-color:#D8DDDD19;
	padding-bottom: 102px;
}
.mobile_wraper img{
	background: #FFFFFF 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 16px #00000029;
	border-radius: 10px;
	opacity: 1;
}
.forget__butn{
	max-width: 251px;
    width: 100%;
}
.forother__butn {
    max-width: 227px;
    width: 100%;
}
.mobile-text .mola{
	color: #373737;
}
.circle-block{
	width: 66px;
    height: 66px;   
    background: #F1F1F1 0% 0% no-repeat padding-box;
    opacity: 1;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Profile_wraper::before{
	content: "";
	display: block;
	background-image: url(../images/message.png);
	background-repeat: no-repeat;
	position: absolute;
	width: 236px;
	height: 154px;
	top: -81px;
    left: 0;
}
.Profile_wraper::after{
	content: "";
	display: block;
	background-image: url(../images/youtube.png);
	background-repeat: no-repeat;
	position: absolute;
	width: 167px;
	height: 84px;
	bottom: -47px;
    right: -46px;
}
.reaction-action{
	margin-top: -62px;
	z-index: 99;
	position: relative;
}

.aljadid__video .slick-prev {
    left: 123px;
    z-index: 99999;
}
.aljadid__video .slick-track:before, .slick-track:after {
    display: table;
    content: "";
}
.aljadid__video .slick-next {
    right: 171px;
    z-index: 99999;
}
.aljadid__video .slick-prev:before {
	content: "";
    color: red;
	background: url(../images/left-arrow.png);
	height: 53px;
    width: 53px;
    display: block;
}
.aljadid__video .slick-next:before {
    content: "";
    color: red;
	background: url(../images/right-arrow.png);
	height: 53px;
    width: 53px;
    display: block;
	
}
.faqat-wraper{
	background-image: url(../images/sorat.png);
    width: 100%;
    display: block;
	min-height: 432px;;
	width: 100%;
	position: relative;
	background-repeat: no-repeat;
}
.faqat-text{
	height: 100%;
    min-height: 432px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.shahat-text h2{
	font-size: 120px;
	text-shadow: 0px 3px 6px #00000000;
	line-height: 116px;
}
.shahdat-wrapper .shahadat-slider{
	max-width: 100%;
	min-height: 381px;
	box-shadow: 0px 0px 18px #C6C6C666;
	border: 1px solid #E7E7E7;
	border-radius: 4px;
	opacity: 1;
}
.shahdat-wrapper .slick-prev {
    left: 45px;
	z-index: 999;
}
.shahdat-wrapper .slick-next {
    right: 41px;
}
.shahdat-wrapper .slick-slide {
	transform: scale(0.8);
	transition: transform 0.5s;
	opacity: 0.4;
  }
.slick-slide.slick-active.slick-center.slick-current{
	opacity: 1;

}
  .shahdat-wrapper .slick-slide.slick-center {
	transform: scale(1);
  }
  .shahdat-wrapper .slick-prev:before {
    content: "";
    color: red;
	background-image: url(../images/nextone.png);
	width: 25px;
	height: 21px;
	display: block;
}
.shahadat-slider{
	position: relative;
}
.shahdat-wrapper .slick-slide::after{
	content: "";
    color: red;
	background-image: url(../images/qoute.png);
	width: 48px;
	height: 48px;
	display: block;
    position: absolute;
    bottom: -22px;
    right: 100px;
        filter: invert(36%) sepia(85%) saturate(2204%) hue-rotate(3deg) brightness(105%) contrast(103%);
}
.shahdat-wrapper .slick-list{
	padding-bottom: 30px !important;
}


.slick-next:before {
	content: "";
    color: red;
	background-image: url(../images/prev.png);
	width: 25px;
	height: 21px;
	display: block;
}
.albi-content{
	background: #FF5D00 0% 0% no-repeat padding-box;
	opacity: 1;
	min-height: 192px;
	width: 100%;
	position: relative;
}
.copy-right{
	margin-top: 30px;
}
.nonimg{
	top: -82px;
    right: 0;
}
.albi-listing ul li:hover {
	color: #101010;
}
[data-headlessui-state="selected"] svg .fill_tab   {
	fill: white;
	color: white;
  }

  [data-headlessui-state="selected"] .alwai   {
	color: white;
  }
.products-tabs-wrapper  [data-headlessui-state="selected"] .alwai   {
	color: black;
  }
.search_input input{
	border: 1px solid #e3e3e3;
	position: absolute;
	padding: 7px 20px;
    width: 200px;
    bottom: -50px;
    border-radius: 6px;
	right: -174px;
	outline: none;

}
.user-cion span {
    font-size: 25px;
    color: #AAAAAA;
}
.user-img{
	margin-right: 10px;
}
.hamburger-lines {
    align-items: center;
    cursor: pointer;
    display: none;
    flex-direction: column;
    height: 28px;
    justify-content: space-between;
    width: 35px;
}


.hamburger-lines.active .line1 {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
.hamburger-lines .line1 {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    transition: -webkit-transform .3s ease-in-out;
    transition: transform .3s ease-in-out;
    transition: transform .3s ease-in-out,-webkit-transform .3s ease-in-out;
}
.hamburger-lines .line {
    background: #0d1953;
    border-radius: 10px;
    display: block;
    height: 3px;
    width: 100%;
}

.hamburger-lines.active .line2 {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
}

.hamburger-lines .line2 {
    transition: -webkit-transform .1s ease-in-out;
    transition: transform .1s ease-in-out;
    transition: transform .1s ease-in-out,-webkit-transform .1s ease-in-out;
}
.hamburger-lines.active .line3 {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transform-origin: -1px 100%;
    transform-origin: -1px 100%;
}
.hamburger-lines .line3 {
    -webkit-transform-origin: 0 100%;
    transform-origin: 0 100%;
    transition: -webkit-transform .3s ease-in-out;
    transition: transform .3s ease-in-out;
    transition: transform .3s ease-in-out,-webkit-transform .3s ease-in-out;
}
.hidden {
    display: none;
}

.desktop-none{
	display:none
}
.star-icon img {
	width: 31px;
}
.f-size-22{
	font-size: 22px;
}
.top-scroll-img button{
    position: absolute;
    left: 150px;
    bottom: 0;
  }
  .main-navbar ul li a {
    font-size: 25px;
    font-weight: 700;
}
  .contry-color{
	color: #828294;
  }
  .listing-country:hover .contry-color{
	color: white;
  }

  .site__header__inner ul li a.active {
	color: #FF5D00;
	border-bottom: 4px solid #FF5D00;
	border-radius: 0px;
    padding-bottom: 6px;
 }
 .max-w-content{
	max-width: 496px;
 }
.start_icon svg{
	height: 31px;
	width:31px;
}
.blank_svg path{ 
	fill: #e3e3e3 !important;
}
.field-wrapper {
    width: 30%;
    margin-top: 25px;
  }
  label.subscribe-lable {
    width: 100%;
    background: #fff;
    padding: 8px;
    border-radius: 35px;
    font-size: 27px;
}
.field-wrapper.buton-holder .theme-btn {
    max-width: 239px;
    height: 56px;
    margin-right: -157px;
    background: #000;
    display: flex;
    align-items: center;
    justify-content: center;
}
.subscribe-wraper button{
    color: white !important;
    ;
}
.subscribe--modal label.lable-style{
  text-align: right;
}
.subscribe--modal .form-control{
  height: 50px;
}
.states-header.modal-header{
  padding: 25px 10px;
  border-radius: 0;
  width: 100%;
  background-color: #FF5D00;
  background-image: url(../../assets/images/bashbaord/dashfigure-bg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  align-items: end;
  min-height: 0;
}
.states-header.modal-header h3{
  text-align: right;
  color: #fff;
  font-size: 26px;
}
button.modal-close {
  background: #fff;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}
.product-faqat-wraper .main-wraper-faqat {
	margin-top: 0px;
}

.product-description.course__descrition {

    padding: 35px 30px 30px 35px;
}
.file_iameg{
    background: url(../images/files.png);
    background-repeat: no-repeat;
    background-size: contain !important;
}
.audio_corse audio{
    width: 100%;
}
.left-reviews-section_course{
    box-shadow: 0px 0px 20px #0000000F;
    padding: 20px;
    border-radius: 6px;
}
.audio_corse {
   padding: 80px 0px;
}
.artical-box-wraper.courses_file_wraper{
    min-height: 200px;
}
.corses_videos{
    padding: 49px 0px;   
}
.footer::after{
    content: "";
    background-image: url(../images/non.png);
    position: absolute;
    width: 469px;
    height: 267px;
    right: 0;
    bottom: 0;
}
.course__detail_tabs [data-headlessui-state=selected] svg .fill_tab {
    fill: #ff5d00;
    color: white;
}

.socil-listing ul li{
    background: white;
    height: 30px;
    width: 30px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.socil-listing ul li svg path{
    fill: #ff5d00;
}
.socil-listing ul li:hover  {
    background: black;
    fill: #d5ad67;
}
.socil-listing ul li:hover svg path {
    fill: white;
}
.loader{
    display: flex;
align-content: center;
justify-content: center;
}
.loader img{
    width: 60px;
}
.file_format_header_wrapper .artical-info.corses_videos.custom_artical_courses {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0px 0;
}
.file_format_header_wrapper .left-reviews-section_course.main_left_part {
    margin-bottom: 20px;
    box-shadow: 0px 0px 0px rgb(0 0 0 / 6%);
    padding: 0px 8px 0 0px;
}
.file_format_header_wrapper .remove_hover_bg:hover{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: unset;
    border-radius: 10px;
}
.file_format_header_wrapper .hover--layout.remove_hover_bg{
    visibility: visible;
   display: flex;
   justify-content: space-between;
    align-items: center;
    background-color: unset;
}
.file_format_header_wrapper .folder_hidden{
    background-image: unset;
}
.file_format_header_wrapper .artical-box-wraper.courses_file_wraper.file_iameg.folder_hidden {
    min-height: 86px;
    }
.file_format_header_wrapper .artical-box-wraper:hover .hover--layout.remove_hover_bg{
    height: 100%;
    visibility: visible;
    z-index: 2;
    display: flex;
    justify-content: space-between;
     align-items: center;
     flex-direction: unset;
  
}
.file_format_header_wrapper .custom_audio_course{
    display: flex;
    justify-content: space-around;
    align-items: center;
    min-height: 86px;
    padding: 0;
}
.file_format_header_wrapper .audio_corse.custom_audio_course audio{
    max-width: 253px;
}
.course_file_accordin{
    border: 2px solid #d5a450;
    color: #000;
}
.file_format_header_wrapper .video_orginal_name {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    min-width: 600px;
    max-width: 602px;
}
.file_format_header_wrapper .video_file_format {
    min-width: 285px;
    text-align: center;
}
.file_format_header_wrapper .play_cus_btn {
    min-width: 111px;
    margin: 14px auto;

}
.file_format_header_wrapper .main_file_format{
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-weight: bold;
    margin-bottom: 30px;
    font-size: 19px;
}
.artical-detail-baner.cms-pages-baner::after {
    width: 100%;
    background: transparent linear-gradient(90deg, rgba(11, 4, 0, 0.2) 0%, rgba(0, 0, 0, 0.431372549) 50%, rgba(11, 4, 0, 0.8) 100%) 0% 0% no-repeat padding-box;
}
.max-width-input{
    max-width: 250px;
}
.video_format_audio {
    min-width: 308px;
    text-align: center;
}

.file_format_header_wrapper  .main_file_format_scroll {
    max-height: 1460px;
    overflow-y: scroll;
    padding: 9px 5px 9px 6px;
}
.file_format_header_wrapper .cus_download_btn{
    max-width: 111px;
    margin: 0 auto;
 
}

.main_file_format_scroll.scroll_bar::-webkit-scrollbar-thumb {
    background: #d5ad67 !important;
    height: 10px!important;
    border: 4px solid #c9a93600;
    border-radius: 10px;
    box-shadow: inset 0 0 5px #d5ad67;
   
}
.main_file_format_scroll.scroll_bar::-webkit-scrollbar-track{
    background-color: transparent!important;
    box-shadow: unset;
} 
.main_file_format_scroll.scroll_bar::-webkit-scrollbar {
    width: 5px;
    background: unset;
    box-shadow: unset;
}
.main_watch_video_wrapper .courses_detail_video.watch_video{
    max-width: 70%;
    width: 100%;
    margin:0 auto;
}
.main_watch_video_wrapper{
    margin:30px 0;
}
.main_watch_video_wrapper .main_watch_video_header {
    max-width: 700px;
    margin: 24px auto;
    text-align: center;
}
 .fullpage-loader-holder {
//     position: fixed;
//  left: 0;
//  top: 0;
//  right: 0;
//  bottom: 0;
 z-index: 10;
 background: rgba(0,0,0,.7);
 z-index: 9999;
} 
.fullpage-loader-holder  img{
    filter: invert(40%) sepia(19%) saturate(7081%) hue-rotate(2deg) brightness(104%) contrast(104%);
}
.site__loader{
    width: 200px;
    height: 200px;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);

}
.main-panel-ste.site__content{
    padding: 30px 15px;
    min-height: calc(100vh - 125px);
    background: transparent;
}
.theme-btn.theme_views_btn {
    padding: 5px 11px;
    font-size: 12px;
    border-radius: 0;
}
.theme-btn.theme_views_btn:disabled{
    background-color: #e3e3e3; 
    color:#000;
}
.views_btn_wraper {
    border-radius: 50px;
}

.minus-price{
    color: #9e9e9e;
    text-decoration: line-through;
}
.aljadid__video .coleague-wrap {
    max-width: 425px;
}
.bg-green{
    background-color: green;
}

// plan card design
.plans_cards{
    display: flex;
    flex-wrap: wrap;
    justify-content:space-between;
    margin-bottom: 60px;
}
button.theme-btn.plan_subscribe_btn {
    padding: 6px 16px;
    margin-top: 15px;
}
.benefits_card.plan_subscribe_card {
    max-width: 349px;
    box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
}
.line_height_64{
    line-height: 64px
}
.plan_description{
    height: 107px;
    overflow-y: scroll;
    padding: 0 6px;
}

.plan_description::-webkit-scrollbar-thumb {
    background: #d5ad67 !important;
    height: 10px!important;
    border: 4px solid #c9a93600;
    border-radius: 10px;
    box-shadow: inset 0 0 5px #d5ad67;
   
}
.plan_description::-webkit-scrollbar-track{
    background-color: transparent!important;
    box-shadow: unset;
} 
.plan_description::-webkit-scrollbar {
    width: 5px;
    background: unset;
    box-shadow: unset;
}
.plan_name{
    position: relative;
    margin-bottom: 10px;
}
.plan_name::after{
    position: absolute;
    content: "";
    width: 26px;
    height: 4px;
    background-color: #D59423;
    bottom: 3px;
    left: 117px;

}
.no_course_fd {
    position: absolute;
    left: 50%;
    font-weight: 700;
    font-size: 52px;
    transform: translateX(-50%);
}
.f-size-27{
    font-size: 27px;
}
.courses_join_btn{
    margin-top: 77px;
    z-index: 1;
}
.plan_sup{
    font-size: 24px;
    top: -34px;
}
.Aljadid__content .aljadid_card_section{
    background-size: cover;
}
button.theme-btn.theme_views_btn.border_radi {
    border-radius: 50px;
  
}
.cms-pages-baner.chechout_banner.terms_banner{
    padding: 70px 0px;
}
.bg-yellow{
    background-color: #d5a450;
}
.bg-dark-yellow{
    background-color: #D59423;
}
.border_colapse{
    border: 2px solid #D59423;
    border-top: none;
}
.collapse_round{
    border-radius:6px 6px 0px 0px ;
}
.check_out_number{
    height: 21px;
    width: 21px;
    border: 2px solid #D59423;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #D59423;
    color: white;
    bottom: -5px;
    left: 14px;
}
 svg .fill_tab:hover{
    fill: white;
 }
// .alwai:hover{
//     color: white;
//  }
p{
    text-align: justify ;
}
.slash-icon {
    position: relative;
  }
  .slash-icon::before {
    content: "";
    position: absolute;
    top: 48%;
    left: 5px;
    right: 0px;
    height: 1px;
    background-color: black;
    border-bottom: 1px solid #cdcfd4;
    width: 23px;
    transform: rotate(140deg);
  }
  .no_course_fd_btn{
    position: absolute;
    left: 50%;
    font-weight: 700;
    font-size: 25px;
    transform: translateX(-50%);
    bottom: -74px;
  }
.cms-pages-baner.chechout_banner.page_not_found{
    padding: 156px 0px ;
}
.max-w-896{
    max-width: 896px;
}
.listing_alim ul li{
    border: 1px solid #E7E7E7;
    border-radius: 4px;
    padding: 15px 46px;
    max-width: 654px;
    position: relative;
}
.light_purple{
    background: #E3DDD5 0% 0% no-repeat padding-box;
}
.dark-purple{
    background: #DED4D6 0% 0% no-repeat padding-box;
}
.light_blue{
    background: #D8DDDD 0% 0% no-repeat padding-box;
}
.listing_alim ul li:nth-child(even){
    margin-right: 60px;
}
.social__icons_main{
    width: 70px;
    height: 70px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #E3DDD5;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: -33px;
    bottom: 50%;
    transform: translatey(50%);
}
.faq_no_found{
    color: #FF5D00;
    text-align: center;
}
.left-reviews-section_course.main_left_part svg{
position: absolute;
left: 41px;
top: 36px;
width: 30px;
height: 42px;
color: #000;
stroke: #000;
}
.f-size-52{
    font-size: 52px;
}
.font-size-27{
    font-size: 27px;
    line-height: 25px;
}
.orange-color{
    color:#FF5D00;
}
.orange_filter img{
    filter: invert(40%) sepia(89%) saturate(2308%) hue-rotate(359deg) brightness(102%) contrast(111%);

}
.f-size-180{
    font-size: 180px;
    line-height: 111px;
}
.bg-orange{
    background-color:#FF5D00 ;
}