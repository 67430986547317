.cms-pages-baner.contact-baner{
  background-image: url(../../../assets/images/contactus-bg.png);
}
.cms-pages-baner.contact-baner::after {
  background: transparent linear-gradient(90deg, #000000 0%, #D8DDDD00 100%) 0% 0% no-repeat padding-box;
}
.form--wraper{
  position: relative;
}
.form--wraper::before{
  content: "";
  position: absolute;
  background-image: url(../../../assets/images/form-top-img.png);
  background-size: 100%;
  background-repeat: no-repeat;
  right: 0;
  top: 0;
  width: 375px;
  height: 255px;
  z-index: 1;
}
.form--wraper::after {
  content: "";
  position: absolute;
  left: 42%;
  top: 50%;
  width: 191px;
  height: 100%;
  z-index: 1;
  background: #D8DDDD;
  transform: translate(-50%, -50%);
}
.contactus--form .common-card-style{
  padding: 70px;
}

form#contact-form {
  padding-top: 65px;
}

.contact-right-form {
  padding: 0 0 0 50px;
  z-index: 2;
  position: relative;
}
.contact--info {
  background: #F5F5F5;
  padding: 50px;
  position: relative;
  z-index: 2;
  margin-right: auto;
  width: 83%;
}
ul.contact-sources {
  padding-top: 60px;
}
.contact-sources li{
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.source-name{
  margin-left: 16px;
}
.source-lato{
  font-family: 'Lato';
  font-size: 17px;
  font-weight: normal;
}
span.img-container {
  width: 48px;
  height: 48px;
  background: #D8DDDD;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  margin-left: 19px;
}
.map-setting {
  height: 180px;
  width: 100%;
}
iframe.map {
  box-shadow: 0px 0px 16px #0000000F;
  border: 1px solid #FFFFFF;
  border-radius: 10px;
  height: 100%;
}
.social-icons {
    padding-top: 50px;
}
ul.social-links {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 47px;
}
ul.social-links li{
  padding: 0 11px;
  cursor: pointer;
}
ul.social-links li a img{
  width: 32px;
  height: 22px;
}

@media only screen and (max-width: 1600px) {
 
  .contactus--form .common-card-style {
    padding: 50px;
  }
  form#contact-form {
    padding-top: 40px;
  }
  ul.contact-sources {
    padding-top: 40px;
  }
  .form-control,.common-select-style__control{
    height: 50px;
  }
  .form--wraper::before {
    width: 305px;
    height: 186px;
    background-size: 100% 100%;
  }
  .contact--info {
    padding: 35px;
  }
  .social-icons {
    padding-top: 30px;
  }
  ul.social-links{
    padding-top: 30px;
  }
}
@media only screen and (max-width: 1480px) {
.form-control {
  height: 50px;
  font-size: 14px;
}
}
@media only screen and (max-width: 1367px) {
  .source-lato {
    font-size: 15px;
  }
  .source-name {
    margin-left: 10px;
    font-size: 30px;
}
  .contact--info {
    width: 88%;
  }
  .contactus--form .common-card-style {
    padding: 40px;
  }
  form#contact-form {
    padding-top: 30px;
  }
  .form--wraper::before {
    width: 209px;
    height: 158px;
  }
  .form-control,.common-select-style__control {
    height: 50px;
    font-size: 15px;
  }
  label.lable-style {
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 2px;
}
  .common-select-style__control{
    padding: 2px 12px;
  }
  ul.contact-sources {
    padding-top: 30px;
  }
  ul.social-links li {
    padding: 0 8px;
    cursor: pointer;
  }
  .form--wraper::after {
    left: 44%;
    width: 151px;
  }
  .contact-sources li{
    flex-wrap: wrap;
  }
 
}
@media only screen and (max-width: 991px) { 
  .form--wraper::after{
    display: none;
  }
  .contact--info {
    width: 100%;
    margin-top: 0px;
  }
  .contact-right-form{
    padding: 0;
  }
  .contact-right-form .theme-btn {
    font-size: 15px;
    margin: 34px auto 0;
  }
 
}
@media only screen and (max-width: 767px) {
  .contact-right-form {
    padding: 0 0 30px 0px;
  }
}
@media only screen and (max-width: 575px) {
  form#contact-form {
    padding-top: 15px;
  }
  .contact-right-form .theme-btn {
    margin: 0px auto 0;
}
  .contact--info {
    margin-top: 0px;
  }
  ul.contact-sources {
    padding-top: 15px;
  }
  .contactus--form .common-card-style,.contact--info {
    padding: 20px;
}
  .form--wraper::before {
    width: 195px;
    height: 113px;
  }
  .contact-sources li {
    flex-direction: column;
  }
}