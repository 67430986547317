.cms-pages-baner {
    background-repeat: no-repeat;
    background-size: 100%;
    padding: 125px 0;
    position: relative;
}
.cms-pages-baner::after{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 800px;
    height: 100%;
    opacity: 1;
    z-index: 1;
  }
.cms-content-heading {
    max-width: 645px;
    margin-right: auto;
    text-align: right;
    z-index: 2;
    position: relative;
}
@media only screen and (max-width: 1399px) {
    .cms-pages-baner::after {
      width: 600px;
    }
    .cms-pages-baner {
      padding: 87px 0;
    }
    .cms-content-heading {
      max-width: 450px;
    }
  }
  @media (max-width:1199px){
    .cms-pages-baner {
      background-size: cover;
      background-position-x: center;
  }
  }
  @media only screen and (max-width: 991px) {
    .cms-pages-baner.contact-baner::after {
      width: 400px;
    }
    .cms-pages-baner.contact-baner {
      padding: 34px 0;
      background-size: cover;
      background-position: center center;
  }
    .cms-content-heading {
      max-width: 350px;
    }
    
  }
  @media only screen and (max-width: 575px) {
    .cms-content-heading {
      max-width: 100%;
      text-align: center;
  }
    .contact--info h2,.contact-right-form h2{
      text-align: center;
      width: 100%;
    }
    .cms-pages-baner {
      padding: 40px 0;
}
}

