.cms-pages-baner.event-baner{
    background-image: url(../../../assets/images/eventbg.png);
    background-repeat: no-repeat;
    background-size: 100%;
    padding:125px 0;
    position: relative;
    background-size: cover;
  }
  .cms-pages-baner.event-baner::after {
    background: transparent linear-gradient(91deg, #000000 0%, #D8DDDD00 100%) 0% 0% no-repeat padding-box;
  }
  .event-baner .cms-content-heading {
    max-width: 545px;
    text-align: right;
    z-index: 2;
    position: relative;
  }
  .text-100{
    font-size: 100px;
  }
.f-size-70{
    font-size: 70px;
    line-height: 40px;
}
.events_number{
    letter-spacing: 0px;
    color: #373737;
    opacity: 1;
    font-family: 'Lato';

}
.event__date{
    box-shadow: 0px 0px 20px #0000000F;
    border-radius: 10px;
    opacity: 1;
    max-width: 188px;
    flex: 0 0 188px;
    width: 100%;
    min-height: 188px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
}
.event-pleasent{
    background-image:url(../../../assets//images/eventone.png) ;
    width: 100%;
    height: 402px;
    background-repeat: no-repeat;
    width: 375px;
    border-radius: 10px;
    opacity: 1;
    position: relative;
    background-size: cover;
    background-position-x: center;
}
.event_second{
    background-image:url(../../../assets//images/eventsecond.png) ;
}
.event-third{
    background-image:url(../../../assets//images/eventthird.png) ;
    
}
.eventfour{
    background-image:url(../../../assets//images/eventfour.png) ;
  
}
.event-pleasent::after{
    content: "";
    background: transparent linear-gradient(180deg, #FFFFFF00 0%, #55555500 16%, #00000019 57%, #000000C6 100%) 0% 0% no-repeat padding-box;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
}
.event-pleasent p{
    height: 100%;
    display: flex;
    place-items: end;
    text-align: center;
    justify-content: center;
    z-index: 1;
    position: relative;
    padding-bottom: 60px;
    border: 2px solid #dd9423;
}
.ribbon-grey{
    background-color: #D8DDDD;
}
.event-detail{
    background: #F5F5F5 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    padding: 38px;
    max-width: 863px;
}
.event-dates-content{
    border-bottom: 1px solid #EFEFEF;
    padding-bottom: 30px;
}
.border-bottom-none{
    border: none;    
}
.event-detail .ribbon{
    margin-right: -50px;
}
.d-content{
    display: contents;
}
@media (max-width:1600px){
    .event-detail {
        max-width: 763px;
    }
    .event-pleasent {
        height: 334px;
    }
}

@media (max-width:1367px){
    .event-detail {
        padding: 15px;
    }
    .event-detail .ribbon {
        margin-right: -24px;
    }
    .event-detail {
        max-width: 663px;
    }
    .f-size-70{
        font-size: 50px;
    }
.event__date {
    max-width: 137px;
    flex: 0 0 137px;
    min-height: 138px;
}
}
@media(max-width:1280px){
    .event-pleasent {
        width: 300px;
        flex: 0 0 300px;
      }
      .event__date {
        max-width: 110px;
        flex: 0 0 110px;
        min-height: 112px;
      }
}
@media (max-width:768px){
    .event-dates-content {
        flex-direction: column;
    }
    .d-content{
        display: flex;
        margin-bottom: 20px;
    }
    .event-pleasent{
        max-width: 100%;
        margin-left: 0;
    }
    .f-size-70{
        font-size: 40px;
    }
    .cms-pages-baner.event-baner {
     
        padding: 80px 0;
    }
    .sucesful_content {
        left: 10px;
    }
}

@media (max-width:480px){
    .d-content {
        display: flex;
        margin-bottom: 20px;
        flex-direction: column;
    }
    .event__date {
        max-width: 100%;
        flex: 0 0 100%;
        margin-bottom: 20px;
    }
    .event-pleasent p {
    
        padding-top: 29px;
    }
    .event-baner .cms-content-heading {
        text-align: center;
    }
    .cms-pages-baner.event-baner {
     
        padding: 49px 0;
    }
    
}
