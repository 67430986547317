.file-input {
    position: relative;
    display: inline-block;
  }
  
  .file-input input[type="file"] {
    display: none;
  }
  
  .file-input label {
    color: #fff;
    cursor: pointer;
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }

  .image-size{
    width: 349px;
    height: 349px;
    object-fit: cover;
    border-radius: 50%;
    outline: 10px solid white;
    margin: 11px;
  }

  .person_name{
    color: #FF5D00;
    text-shadow: 0px 3px 6px #00000000;
    opacity: 1;
    font-size: 36px;
    padding-top: 40px;
  }
  .profile_user{
    background: #F5F5F5 0% 0% no-repeat padding-box;
    border: 1px solid #E7E7E7;
    border-radius: 4px;
    opacity: 1;
    max-width: 434px;
    padding: 82px 0px;
    text-align: center;
    margin-left: 40px;
    width: 100%;
  }
  .personal__informa{
    padding: 43px;
    background: #F5F5F5 0% 0% no-repeat padding-box;
    border: 1px solid #E7E7E7;
    border-radius: 4px;
    opacity: 1;
  }
  .rmdp-input {
    border: 1px solid #D8DDDD;
    border-radius: 10px;
    
}
.dashboard_eye-button {
  position: absolute;
  width: 20px;
  height: 13px;
  left: 12px;
  top: 50%;
  transform: translatey(-50%);
}
  @media only screen and (max-width: 1600px) {
    .image-size {
      width: 249px;
      height: 249px;
  }
  .profile_user{
      max-width: 300px;
      margin-left: 20px;
      padding: 60px 0;
    }
    .personal__informa {
      padding: 25px;
    }
    .person_name {
      font-size: 28px;
      padding-top: 30px;
  }
  .date_picker input {
    height: 52px;
    background-position-y: calc(100% - 8px);
    background-position-x: 8px;

  }
 
  }
  @media only screen and (max-width: 767px) {
    .image-size {
      width: 149px;
      height: 149px;
    }
    .profile_user{
      margin: 0 auto 20px;
      padding: 20px;
    }
    .personal__informa{
      padding: 15px;
    }
  }