/* scroll */
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px transparent;
    background: #FF5D00;
    box-shadow: inset 0 0 5px #D5AD67;
    border-radius: 10px;
    height: 5px;
}

::-webkit-scrollbar {
    width: 5px;
    background: #fff;
    box-shadow: inset 0 0 5px #D5AD67;
    border-radius: 10px;
    height: 5px;

}

::-webkit-scrollbar-thumb {
    background: #fff;
    border: 4px solid #fff;
    border-radius: 10px;
    box-shadow: inset 0 0 5px #D5AD67;

}

/* check box */
.custom-control.overflow-checkbox .overflow-control-input {
    display: none;
}

.custom-control.overflow-checkbox {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  
  .custom-control.overflow-checkbox .overflow-control-input {
    display: none;
  }
  
  .custom-control.overflow-checkbox .overflow-control-indicator {
    border-radius: 3px;
    display: inline-block;
    position: absolute;
    top: 4px;
    right: 0;
    width: 16px;
    height: 16px;
    border: 2px solid #373737;
    cursor: pointer;
  }
  
  .custom-control.overflow-checkbox .overflow-control-indicator::after {
    content: '';
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    transition: .3s;
    transform: rotateZ(90deg) scale(0);
    width: 10px;
    border-bottom: 4px solid #373737;
    border-right: 4px solid #373737;
    border-radius: 3px;
    top: -2px;
    left: 2px;
  }
  
  .custom-control.overflow-checkbox .overflow-control-indicator::before {
    content: '';
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    transition: .3s;
    width: 10px;
    border-right: 7px solid #fff;
    border-radius: 3px;
    transform: rotateZ(45deg) scale(1);
    top: -4px;
    left: 5px;
    opacity: 0;
  }
  
  .custom-control.overflow-checkbox .overflow-control-input:checked ~ .overflow-control-indicator::after {
    transform: rotateZ(45deg) scale(1);
    top: -6px;
    left: 5px;
  }
  
  .custom-control.overflow-checkbox .overflow-control-input:checked ~ .overflow-control-indicator::before {
    opacity: 1;
  }
  
  .custom-control.overflow-checkbox .overflow-control-input:disabled ~ .overflow-control-indicator {
    opacity: .5;
    border: 2px solid #ccc;
  }
  
  .custom-control.overflow-checkbox .overflow-control-input:disabled ~ .overflow-control-indicator:after {
    border-bottom: 4px solid #ccc;
    border-right: 4px solid #ccc;
  }
  

/*  */

.cart_banner {
    background-image: url('../../../assets/images/cart-baner-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 180px 0;
    display: flex;
    flex-direction: column;
    background-position-y:center ;
    width: 100%;
    align-items: center;
    justify-content: center;
    position: relative;
    background-position-y:center ;
}
.baner--heading{
    font-size: 129px;
    text-align: center;
}
.cart_banner::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 800px;
    height: 100%;
    background: transparent linear-gradient(90deg, #D5AD67ED 0%, #D5AD6705 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
}

.dark-brown {
    color: #5F410C;
}

.light-black {
    color: #373737cc;
}

.light-brown {
    color: #5F410C;
    text-shadow: 0px 3px 6px #00000000;
    font-weight: 300;
    line-height: 50px;
}

.cart--items-sec {
    background-image: url(../../../assets/images/Union.png);
    background-repeat: repeat;
}

// .cart-imgwraper {
//     box-shadow: 0px 3px 36px rgba(0, 0, 0, 0.3411764706);
//     border: 5px solid #FFFFFF;
//     border-radius: 10px;
//     width: 750px;
//     min-height: 640px;
// }
.cart-imgwraper img{
    object-fit: contain;
    width: 100%;
    height: 100%;
    box-shadow: 0px 3px 36px rgba(0, 0, 0, 0.3411764706);
    border: 5px solid #FFFFFF;
    border-radius: 10px;
    // min-height: 540px;
}
.addcart-lottie {
    background: #fff;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    left: 3px;
    top: 1px;

}
ul.rating-stars-list li {
    display: inline-flex;
}

.quantity {
    display: flex;
    align-items: center;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #E3DDD5;
    border-radius: 30px;
    max-width: 131px;
    height: 54px;
    justify-content: space-between;
    padding: 7px 3px;
}

.quantity .count {
    width: 40px;
    text-align: center;
}

.quantity .increment,
.quantity .decrement {
    display: inline-block;
    width: 40px;
    height: 40px;
    text-align: center;
    border: none;
    background-color: #ddd;
    cursor: pointer;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    font-size: 27px;
    line-height: 38px;
}

.increment:hover,
.decrement:hover {
    background-color: #D5AD67;
    color: #fff;
}

.quantity .increment:active,
.quantity .decrement:active {
    background-color: #D5AD67;
    color: #fff;
}

.add-to-cart-btn {
    padding: 10px 20px 10px 44px;
    width: 210px;
    height: 54px;
    display: flex;
    justify-content: 20px;
    justify-content: center;
    align-items: center;
}

/* product-description box */
.product-description {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #D8DDDD;
    border-radius: 10px;
    padding: 35px 30px 30px 120px;
}

/*reviews */

.common-card-style {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 20px #0000000F;
    border-radius: 10px;
    padding: 20px;
}
.contact-form.common-card-style {
    padding: 40px 25px;
}
.right-reviews-list li.common-card-style {
    margin-bottom: 15px;
    direction: rtl;
}

ul.start-list {
    display: inline-flex;
}

ul.start-list li {
    padding: 3px;
}

.right-reviews-list {
    max-height: 492px;
    overflow-y: auto;
    direction: ltr;
}
.reveiw--msg {
    padding-top: 15px;
}
.reveiw--msg .message {
    width: calc(100% - 100px);
    margin-right: 10px;
    text-align: right;
}

.profile-img {
    width: 70px;
    height: 70px;
    background: #D8DDDD;
    border-radius: 50px;
    padding: 20px;
}

.profile-img img {
    width: 100%;
    object-fit: contain;
}
.courses_detail_video video{
    width: 100%;
    height: 540px;
    box-shadow: 0px 3px 36px #00000057;
    border: 5px solid #FFFFFF;
    border-radius: 10px;
    object-fit: cover;
}
@media only screen and (max-width: 1600px) {
    .cart_banner{
        padding: 160px 0;
    }
    .baner--heading {
        font-size: 95px;
        line-height: 100px;
    }
    .circle-block {
        max-width: 37px;
        height: 37px;
        width: 100%;
    }
    .reveiw--msg .message{
        margin-right: 30px;
    }
}
@media only screen and (max-width: 1367px) {
    .cart_banner {
        padding: 128px 0;
    }
    .courses_detail_video video {
        height: 402px;
    }
    .baner--heading {
        font-size: 90px;
    }
    .cart_banner::after {
        width: 650px;
    }
}
@media only screen and (max-width: 1199px) {
    .cart_banner {
        padding: 70px 0;
        background-size:100%;
    }
    .baner--heading {
        font-size: 70px;
        line-height: 90px;
    }
    .cart_banner::after {
        width: 550px;
    }
    .product-description{
        padding: 30px;
    }
}
@media only screen and (max-width: 992px) {
    .cart_banner {
        padding: 70px 0;
        background-size:100%;
    }
    .baner--heading {
        font-size: 60px;
        line-height: 54px;
    }
    .cart_banner::after {
        width: 450px;
    }
    .cart-imgwraper {
        max-width: 550px;
    }
}
@media only screen and (max-width: 767px) {
    .cart_banner {
        background-size: cover;
        background-position-x: center;
    }
    .baner--heading {
        font-size: 60px;
        line-height: 54px;
    }
    .cart_banner::after {
        width: 350px;
    }
    .left-reviews-section{
        padding-top: 50px;
    }
}
@media only screen and (max-width: 575px) {
    .naming h6,.review-dates{
        font-size: 14px;
    }
    .cart_banner::after {
        width: 300px;
    }
    .baner--heading {
        font-size: 55px;
        line-height: 28px;
    }
    .common-card-style{
        padding: 10px;
    }
    .product-description {
        padding: 15px;
    }
}
